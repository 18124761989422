var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    label: "LBL0010196",
                    defaultStart: "-6M",
                    defaultEnd: "6M",
                    name: "period",
                  },
                  model: {
                    value: _vm.period,
                    callback: function ($$v) {
                      _vm.period = $$v
                    },
                    expression: "period",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "LBL0010197",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.grid.height,
                  data: _vm.grid.data,
                  rowKey: "proceedingsId",
                  columnSetting: false,
                  expandAll: true,
                },
                on: { linkClick: _vm.linkClick },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props }) {
                      return [
                        [
                          _c("q-btn", {
                            staticClass: "tableinnerBtn",
                            attrs: {
                              flat: "",
                              icon: "description",
                              align: "center",
                              color: "blue-6",
                              label: "",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.linkClickPreview(props.row)
                              },
                            },
                          }),
                        ],
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "LBLREG", icon: "add" },
                              on: { btnClicked: _vm.bookPopup },
                            })
                          : _vm._e(),
                        _c("c-btn", {
                          attrs: { label: "LBLSEARCH", icon: "search" },
                          on: { btnClicked: _vm.getList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: {
                  title: _vm.selectedProceedingsTitle,
                  height: _vm.detailHeight,
                },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-table", {
                        ref: "ogrid",
                        attrs: {
                          title: "회의결과",
                          columns: _vm.ogrid.columns,
                          gridHeight: _vm.gridHeight,
                          data: _vm.data.opinions,
                          filtering: false,
                          columnSetting: false,
                          usePaging: false,
                          noDataLabel: "회의결과가 없습니다.",
                          hideBottom: false,
                          checkClickFlag: false,
                          isExcelDown: false,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }