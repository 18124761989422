<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--회의기간-->
          <c-datepicker
            :range="true"
            label="LBL0010196"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <!--산업안전보건위원회 회의록 목록-->
        <c-table
          ref="table"
          title="LBL0010197"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          rowKey="proceedingsId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <template v-slot:customArea="{ props }">
            <template>
              <q-btn
                class="tableinnerBtn"
                flat
                icon="description"
                align="center"
                color="blue-6"
                label=""
                @click.stop="linkClickPreview(props.row)" />
            </template>
          </template>
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!--등록-->
              <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="bookPopup" />
              <!--검색-->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-card :title="selectedProceedingsTitle" class="cardClassDetailForm" :height="detailHeight">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-table
                ref="ogrid"
                title="회의결과"
                :columns="ogrid.columns"
                :gridHeight="gridHeight"
                :data="data.opinions"
                :filtering="false"
                :columnSetting="false"
                :usePaging="false"
                noDataLabel="회의결과가 없습니다."
                :hideBottom="false"
                :checkClickFlag="false"
                :isExcelDown="false"
              >
              </c-table>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'proceedings',
  data() {
    return {
      gridHeight: '',
      detailHeight: '',
      selectedContents: '<div style="margin:0 auto;font-weight:700;">작성된 산업안전보건위원회가 없습니다.</div>',
      selectedProceedingsTitle : '회의내용',
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'proceedingsTitle',
            field: 'proceedingsTitle',
            //회의제목
            label: 'LBL0010198',
            align: 'left',
            type: "link",
            sortable: true,
          },
          {
            name: 'proceedingsDate',
            field: 'proceedingsDate',
            //회의일
            label: 'LBL0010199',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            // 진행단계
            label: '결재상태',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'custom',
            field: 'custom',
            label: '회의내용',
            align: 'center',
            sortable: false,
            style: 'width:80px',
            type: 'custom'
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        startYmd: '',
        endYmd: '',
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      // attachInfo: {
      //   isSubmit: '',
      //   taskClassCd: 'OSH_PROCEEDINGS',
      //   taskKey: '',
      // },
      ogrid: {
        columns: [
          {
            name: 'opinion',
            field: 'opinion',
            label: '회의결과',
            style: 'width: 80%',
            type: 'html',
            align: 'left',
          },
          {
            name: 'remarkName',
            field: 'remarkName',
            label: '찬반여부',
            align: 'center',
          },
        ],
        data: [],
      },
      data: {
        proceedingsId: '',
        plantCd: '',
        proceedingsDate: '',
        proceedingsContents: '',
        proceedingsTitle: '',
        regUserId: '',
        chgUserId: '',
        
        attendeeInModels: [],
        attendeeOutModels: [],
        deleteAttendeeInModels: [],
        deleteAttendeeOutModels: [],
        opinions: [],
        deleteopinions: [],
      },
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.detailHeight = (window.innerHeight - (250)) + 'px';
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sai.osh.proceedings.list.url;
      this.detailUrl = selectConfig.sai.osh.proceedings.get.url;
      this.gridHeight = (window.innerHeight - 300) + 'px';
      this.getList();
    },
    getList() {
      this.selectedProceedingsTitle = '회의내용'
      this.selectedContents = '<div style="margin:0 auto;font-weight:700;">작성된 산업안전보건위원회가 없습니다.</div>'
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        var cnt = 0
        this.$_.forEach(this.grid.data, _item => {
          if (cnt == 0) {
            this.selectedProceedingsTitle = _item.proceedingsTitle + ' ['+_item.proceedingsDate+']'
            this.getDetail(_item.proceedingsId)
          }
          // this.$set(this.attachInfo, 'taskKey', _item.proceedingsId)
          // this.$set(this.attachInfo, 'isSubmit', uid())
          cnt++;
        })
        if (this.grid.data.length == 0) {
          this.data = {};
        }
      },);
    },
    linkClick(row) {
      this.bookPopup(row);
    },
    bookPopup(result) {
      this.popupOptions.target = () => import(`${"./proceedingsDetail.vue"}`);
      this.popupOptions.title = 'LBL0010200';   //산업안전보건위원회 회의록 상세
      this.popupOptions.param = {
        proceedingsId: result ? result.proceedingsId : '',
      };
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    linkClickPreview(row) {
      this.selectedProceedingsTitle = row.proceedingsTitle + ' ['+row.proceedingsDate+']'
      this.getDetail(row.proceedingsId)
    },
    getDetail(_proceedingsId) {
      if (_proceedingsId) {
        this.$http.url = this.$format(this.detailUrl, _proceedingsId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
        },);
      }
    },
  }
};
</script>
